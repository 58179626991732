import { useEffect, useRef, useState } from 'react';

export const useScrollDirection = <T extends HTMLElement>(preferEl = false) => {
  const scrollContainerRef = useRef<T>(null);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const domEl = preferEl ? scrollContainerRef.current : window;
    let prevScrollY = window.scrollY;
    const handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(scrollTimeoutRef.current);
      const currentScrollY = window.scrollY;

      if (currentScrollY > prevScrollY) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }
      prevScrollY = currentScrollY;

      scrollTimeoutRef.current = setTimeout(() => {
        setIsScrolling(false);
      }, 600);
    };

    domEl?.addEventListener('scroll', handleScroll);

    return () => {
      domEl?.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimeoutRef.current);
    };
  }, []);

  return { scrollDirection, isScrolling, scrollContainerRef };
};
