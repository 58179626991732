'use client';
import { ReactNode } from 'react';
import { AccountContext } from './account-context';
import { AccountPage } from 'types/account';

export function AccountProvider({
  children,
  accountPageContent,
}: {
  children: ReactNode;
  accountPageContent: AccountPage;
}) {
  return (
    <AccountContext.Provider value={accountPageContent}>
      {children}
    </AccountContext.Provider>
  );
}
