import { useContext, useEffect, useState } from 'react';

import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import { useTranslation } from 'app/i18n/client';
import { Link } from 'components/link';
import Image from 'next/image';
import { CheckList, LogoutIcon, ChevronRightIcon } from 'components/icon';
import ConsentManagementModal from 'components/account/consent-management/modal';
import { handleSignout } from 'utils/sign-out-handler';
import { consenstManagementHref } from 'utils/route-urls';
import { AccountContext } from './account-context/account-context';
import { Component, ComponentType } from 'types/account';
import linkHandler from './account-context/account-link-handler';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import ContentfulLink from 'components/contentful-link';

export default function AccountMobileMenu({
  children,
  language,
  region,
}: {
  children: React.ReactNode;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const pathname = usePathname();
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'account'
  );
  const [isMobile, setIsMobile] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { accountMenuData } = useContext(AccountContext);
  const accountLinks = accountMenuData?.components?.find(
    (component: ComponentType) =>
      component?.component_properties?.component_id ===
      'my-account-account-container-group'
  );
  const pathSuffix = () => {
    if (pathname.includes('order-details')) {
      return 'order-details';
    } else {
      return pathname.split('/').pop();
    }
  };
  const activeRoutes = {
    account: pathSuffix() === 'account',
    address_book: pathSuffix() === 'address-book',
    payment_cards: pathSuffix() === 'payment-cards',
    my_orders: pathSuffix() === 'my-orders',
    order_details: pathSuffix() === 'order-details',
    buy_again: pathSuffix() === 're-order',
    my_wishlist: pathSuffix() === 'my-wishlist',
    refer_earn: pathSuffix() === 'refer-earn',
    consent_management: pathSuffix() === 'consent-management',
    logout: pathSuffix() === 'logout',
  };
  const handleLinkClick = (index: number) => {
    setActiveIndex(index);
  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth < 768);
    }
  }, []);

  return (
    <>
      <div className='mt-4 flex justify-center md:mt-0'>
        <div className='flex h-fit w-full flex-col rounded-md bg-white md:mr-6 md:min-w-[288px] md:max-w-[288px] rtl:ml-6 rtl:mr-0'>
          {accountLinks?.components?.map(
            (
              { link, link_type, name, icon, label }: Component,
              index: number
            ) =>
              !(link === 'LOAD_MY_ACCOUNT_CUSTOMER_REFER_EARN_SCREEN') && (
                <ContentfulLink
                  key={name}
                  item={{
                    link:
                      linkHandler(link) === '/account'
                        ? isMobile
                          ? '/account/my-account'
                          : linkHandler(link)
                        : linkHandler(link),
                    link_type,
                  }}
                  onClick={() => handleLinkClick(index)}
                  className={clsx(
                    'relative flex h-[52px] items-center border-b border-gray-200 p-4 text-custom-base',
                    `${
                      activeIndex === index &&
                      'rounded-t-md !border-b-2 !border-blue bg-blue-100 font-semibold text-blue'
                    }`
                  )}
                  language={language}
                  region={region}
                >
                  <span className='mr-2 rtl:ml-2 rtl:mr-0'>
                    {icon ? (
                      <Image src={icon} alt={label} width={20} height={20} />
                    ) : (
                      <div className='h-5 w-5 rounded-md bg-gray-200'></div>
                    )}
                  </span>
                  <h3>{t(label)}</h3>
                  <ChevronRightIcon className='absolute right-4 h-5 md:hidden rtl:left-0 rtl:right-auto rtl:rotate-180' />
                </ContentfulLink>
              )
          )}
          {isMobile ? (
            <button
              onClick={() => setOpenModal(!openModal)}
              className={clsx(
                'relative flex items-center border-b border-gray-200 p-4 text-custom-base',
                `${
                  activeRoutes.consent_management &&
                  '!border-b-2 !border-blue bg-blue-100 font-semibold text-blue'
                }`
              )}
            >
              <span className='mr-2 rtl:ml-2 rtl:mr-0'>
                <CheckList />
              </span>
              {t('consent_management')}
              <ChevronRightIcon className='absolute right-4 h-5 md:hidden rtl:left-0 rtl:right-auto rtl:rotate-180' />
            </button>
          ) : (
            <Link
              href={consenstManagementHref}
              className={clsx(
                'relative flex items-center border-b border-gray-200 p-4 text-custom-base',
                `${
                  activeRoutes.consent_management &&
                  '!border-b-2 !border-blue bg-blue-100 font-semibold text-blue'
                }`
              )}
            >
              <span className='mr-2 rtl:ml-2 rtl:mr-0'>
                <CheckList />
              </span>
              {t('consent_management')}
              <ChevronRightIcon className='absolute right-4 h-5 md:hidden rtl:left-0 rtl:right-auto rtl:rotate-180' />
            </Link>
          )}
          <form
            onSubmit={(e) => {
              handleSignout(true, e);
            }}
          >
            <button className='relative flex h-[52px] w-full items-center border-b border-gray-200 p-4 text-custom-base'>
              <span className='mr-2 rtl:ml-2 rtl:mr-0'>
                <LogoutIcon />
              </span>
              <h3 className='text-sm'>{t('logout')}</h3>
              <ChevronRightIcon className='absolute right-4 h-5 md:hidden rtl:left-0 rtl:right-auto rtl:rotate-180' />
            </button>
          </form>
        </div>
        <div className='hidden w-full md:block'>{children}</div>
      </div>

      <ConsentManagementModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        language={language}
        region={region}
      />
    </>
  );
}
