'use client';

import { useTranslation } from 'app/i18n/client';
import MobileModal from 'components/mobile-modal';
import { Button } from 'components/buttons/button';
import { Underline } from 'components/buttons/underline';
import { Checkbox } from 'components/checkbox';
import { useState } from 'react';
import { ModalPropsType } from 'types/modal';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

export default function ConsentManagementModal({
  openModal,
  setOpenModal,
  language,
  region,
}: ModalPropsType) {
  const { ts } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'account'
  );
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [checkedTermsConditions, setcheckedTermsConditions] = useState(true);
  const [checkedDataProcessing, setCheckedDataProcessing] = useState(false);
  return (
    <MobileModal
      isOpen={openModal}
      setIsOpen={setOpenModal}
      title={`${ts('consent_management')}`}
      style='bottom'
    >
      <div className='h-fit'>
        <form action='#'>
          <div className='flex w-full flex-col gap-5 rounded-md bg-white'>
            <div className='flex items-center'>
              <Checkbox
                onChange={() => {
                  setcheckedTermsConditions(!checkedTermsConditions);
                  setIsButtonEnabled(true);
                }}
                checked={checkedTermsConditions}
              />
              <h2 className='text-custom-sm leading-[normal] tracking-tight text-gray-dark ltr:pl-2 rtl:pr-2'>
                {ts('i_agree_to_the_marketing_&')}
                <Underline
                  href={'/terms-and-conditions'}
                  className='mx-0.5 !text-custom-sm font-semibold leading-[normal] tracking-[-0.3px] text-gray-dark'
                  afterClassName='bg-gray h-[0.5px] -bottom-[0.3px]'
                >
                  {ts('terms_and_conditions')}
                </Underline>
              </h2>
            </div>
            <hr />
            <div className='flex items-center'>
              <Checkbox
                onChange={() => {
                  setCheckedDataProcessing(!checkedDataProcessing);
                  setIsButtonEnabled(true);
                }}
                checked={checkedDataProcessing}
              />
              <h2 className='text-custom-sm leading-[normal] tracking-[-0.3px] text-gray-dark ltr:pl-2 rtl:pr-2'>
                {ts('i_agree_to_the_data_processing_of_my_health')}
              </h2>
            </div>
            <Button
              className='w-full rounded-md bg-gray px-6 py-3'
              disabled={!isButtonEnabled}
            >
              <span className='text-custom-base font-semibold tracking-[-0.3px] text-light-gray'>
                {ts('update')}
              </span>
            </Button>
          </div>
        </form>
      </div>
    </MobileModal>
  );
}
