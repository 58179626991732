'use client';
import { useState } from 'react';
import { PlusIcon, MinusIcon } from './icon';

type Props = {
  content: JSX.Element;
  title: string | JSX.Element;
  isOpen?: boolean;
  wrapperClassName?: string;
};

function FooterAccordion(props: Props) {
  const [isOpen, setIsOpen] = useState(props.isOpen || false);

  return (
    <div className={props.wrapperClassName}>
      <h2>
        <button
          type='button'
          className='flex w-full items-center justify-between gap-3 py-4 text-custom-base font-semibold md:py-5 rtl:text-right'
          onClick={() => setIsOpen(!isOpen)}
        >
          <span
            className={`${
              isOpen ? 'text-blue' : ''
            } transition-all duration-500`}
          >
            {props.title}
          </span>
          <PlusIcon
            className={`absolute h-[18px] w-[18px] text-gray transition-all duration-500 ltr:right-4 rtl:left-4 ${
              isOpen ? 'invisible opacity-0' : 'visible opacity-100'
            }`}
          />
          <MinusIcon
            className={`absolute h-[18px] w-[18px] text-blue transition-all duration-500 ltr:right-4 rtl:left-4 ${
              isOpen ? 'visible opacity-100' : 'invisible opacity-0'
            }`}
          />
        </button>
      </h2>
      <div
        className={`overflow-hidden ${
          isOpen ? 'max-h-screen' : 'max-h-0'
        } transition-all duration-500`}
        aria-labelledby='accordion-flush-heading-3'
      >
        {props.content}
      </div>
    </div>
  );
}

export default FooterAccordion;
