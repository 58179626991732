/* eslint-disable react/no-children-prop */
'use client';

import { useEffect, useState } from 'react';
import Image from 'next/image';
import { UserIcon, HamburgerIcon, CloseIcon } from 'components/icon';
import clsx from 'clsx';
import { Link } from 'components/link';
import { usePathname } from 'next/navigation';
import { useTranslation } from 'app/i18n/client';
import AccountMobileMenu from 'components/account/mobile-menu';
import { MobileBottomMenuType } from 'types/mobile-bottom-menu';
import { useScrollDirection } from 'hooks/use-scroll-direction.hook';
import tailwindMerge from 'utils/tailwind-merge';
import { plpPageHref } from 'utils/route-urls';
import { PharmacistLink } from './pharmacist-link';
import { getLocalePathFromLanguageRegion } from 'utils/locale';
import { useAppSelector } from 'redux/hooks';
import HydrationWrapper from './hydration-wrapper';
import { motion } from 'framer-motion';

type BottomNavigationItem = {
  label: string;
  link: string;
  icon_normal: string;
  icon_selected: string;
  status: string;
  visibility: string;
};

export default function MobileBottomMenuClient({
  data,
  colorTheme,
  hidePharmacist,
  language,
  region,
}: MobileBottomMenuType) {
  const { t, ts } = useTranslation(
    getLocalePathFromLanguageRegion(language, region),
    'header'
  );
  const currentRoute = usePathname();
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const totalQuantity = useAppSelector((state) => state.basket.totalQuantity);
  const miniProfile = useAppSelector(
    (state) => state.customerReducer.miniProfile
  );

  const { scrollDirection, isScrolling } = useScrollDirection();

  const isScrollingDown = scrollDirection == 'down';
  const [isAnimHovered, setAnimHovered] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimHovered(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (accountMenuOpen) {
      document.body.style.overflowY = 'hidden';
    }

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [accountMenuOpen]);

  if (!data) {
    if (process?.env?.NODE_ENV === 'development') {
      console.warn('Mobile Bottom Menu data is not available');
    }

    return null;
  }

  const lastItemData = data[data.length - 1];

  const activeHomePageClass = (item: any) => {
    return (
      item === '/' &&
      currentRoute.split(
        '/' + language + '-' + region.toLowerCase() + ''
      )[1] === ''
    );
  };

  const activeLinkClass = (route: string) => {
    return activeHomePageClass(route) ||
      (currentRoute?.includes(route) && route !== '/')
      ? 'rounded-lg'
      : '';
  };

  const activeIconClass = (route: string) => {
    return activeHomePageClass(route) ||
      (currentRoute?.includes(route) && route !== '/')
      ? 'text-blue'
      : 'text-gray';
  };

  const activeTextClass = (route: string) => {
    return activeHomePageClass(route) ||
      (currentRoute?.includes(route) && route !== '/')
      ? 'font-bold text-blue'
      : 'text-gray';
  };

  return (
    <>
      <div
        className={tailwindMerge(
          clsx(
            'mobile-bottom-menu duration-400 fixed bottom-0 left-0 right-0 z-50 h-[70px] transition-all ease-in',
            {
              '-bottom-20':
                isScrollingDown &&
                isScrolling &&
                currentRoute?.includes(plpPageHref),
            }
          )
        )}
      >
        {data.length > 0 && (
          <div
            className={clsx(
              'absolute -top-[40px] flex items-center transition-all ease-in',
              'ltr:right-2 rtl:left-2',
              hidePharmacist ? 'hidden' : 'block'
            )}
          >
            <motion.div
              initial={{ scaleX: 0 }}
              animate={{
                scaleX: isAnimHovered ? 1 : 0,
                x: isAnimHovered ? 0 : 30,
              }}
              transition={{
                duration: 0.2,
                delay: isAnimHovered ? 1 : 0,
                repeatType: 'reverse',
              }}
              onMouseEnter={() => setAnimHovered(true)}
              onMouseLeave={() => setAnimHovered(false)}
              style={{ transformOrigin: 'right' }}
            >
              <span className='rounded-xl bg-blue px-3 py-1 text-custom-xs text-white shadow-lg ltr:-mr-3 rtl:-ml-3'>
                {t('e_pharmacist')}
              </span>
            </motion.div>

            <motion.div
              initial={{ x: 30, opacity: 0.5 }}
              animate={{
                x: isAnimHovered ? 0 : 30,
                opacity: isAnimHovered ? 1 : 0.5,
              }}
              transition={{
                duration: 0.5,
                repeatType: 'reverse',
                delay: 0.25,
                type: 'spring',
                stiffness: isAnimHovered ? 500 : 100,
                damping: isAnimHovered ? 100 : 10,
              }}
              onMouseEnter={() => setAnimHovered(true)}
              onMouseLeave={() => setAnimHovered(false)}
            >
              <PharmacistLink language={language} region={region}>
                <Image
                  src='/assets/images/call-pharmacist-animation.png'
                  alt={ts('call_e_pharmacist')}
                  width={0}
                  height={0}
                  sizes='100vw'
                  className='h-10 w-10 ltr:right-0 rtl:left-0'
                />
              </PharmacistLink>
            </motion.div>
          </div>
        )}

        <HydrationWrapper>
          <ul
            className={
              'grid h-full grid-cols-5 items-center gap-2 bg-white px-4 shadow-lg'
            }
            style={{
              backgroundColor: colorTheme.background_color,
              boxShadow: hidePharmacist
                ? 'none'
                : `0 0 48px 0 ${colorTheme.widget_drop_shadow}`,
            }}
          >
            {data
              .slice(0, data.length - 1)
              .map((item: BottomNavigationItem, index: number) => {
                const itemLink =
                  item.link === 'homepage' ? '/' : '/' + item.link;
                const isActive =
                  activeHomePageClass(itemLink.toLocaleLowerCase()) ||
                  (currentRoute?.includes(itemLink.toLocaleLowerCase()) &&
                    item.link !== 'homepage');

                if (item?.visibility !== 'show' || item?.status === 'disable') {
                  return null;
                } else {
                  return (
                    <li
                      key={'navigation-item' + index}
                      onClick={() => setAccountMenuOpen(false)}
                      className='relative'
                    >
                      <Link
                        href={itemLink}
                        className={`flex flex-col items-center space-y-1 p-2 ${activeLinkClass(
                          itemLink.toLocaleLowerCase()
                        )}`}
                        style={{
                          backgroundImage: isActive
                            ? `linear-gradient(to bottom, ${colorTheme.selected_icon_background_gradient[0]}, ${colorTheme.selected_icon_background_gradient[1]})`
                            : '',
                        }}
                      >
                        {isActive ? (
                          <Image
                            src={item.icon_selected}
                            alt={`${item.label[language]} icon`}
                            width={0}
                            height={0}
                            sizes='100vw'
                            className='h-5 w-5'
                          />
                        ) : (
                          <Image
                            src={item.icon_normal}
                            alt={`${item.label[language]} icon`}
                            width={0}
                            height={0}
                            sizes='100vw'
                            className='h-5 w-5'
                          />
                        )}
                        <HydrationWrapper>
                          {totalQuantity > 0 &&
                            item?.link?.toLocaleUpperCase() === 'MY-CART' && (
                              <span
                                className={clsx(
                                  'absolute right-0 top-0 h-[18px] min-w-[18px] rounded-full p-1',
                                  'border-2 border-white bg-blue text-custom-xs text-white',
                                  'flex items-center justify-center'
                                )}
                              >
                                {totalQuantity}
                              </span>
                            )}
                        </HydrationWrapper>

                        <span
                          className={`text-center text-custom-xs text-gray ${activeTextClass(
                            itemLink.toLocaleLowerCase()
                          )}`}
                          style={{
                            color: isActive
                              ? colorTheme.label_selected_text_color
                              : colorTheme.label_text_color,
                          }}
                        >
                          {item.label[language]}
                        </span>
                      </Link>
                    </li>
                  );
                }
              })}

            {lastItemData && (
              <>
                {miniProfile && (
                  <li>
                    <button
                      onClick={() => setAccountMenuOpen(!accountMenuOpen)}
                      className={`flex flex-col items-center space-y-1 p-2 ${
                        accountMenuOpen
                          ? 'rounded-lg bg-gradient-to-b from-cyan to-white'
                          : ''
                      } ${activeLinkClass('account')}`}
                      style={{
                        backgroundImage: currentRoute?.includes('account')
                          ? `linear-gradient(to bottom, ${colorTheme.selected_icon_background_gradient[0]}, ${colorTheme.selected_icon_background_gradient[1]})`
                          : '',
                      }}
                    >
                      <HydrationWrapper>
                        {!accountMenuOpen ? (
                          <HamburgerIcon className='has-color-rect fill-current text-gray' />
                        ) : (
                          <CloseIcon />
                        )}
                      </HydrationWrapper>

                      <span
                        className={`text-center text-custom-xs ${
                          accountMenuOpen ? 'font-bold text-blue' : 'text-gray'
                        } ${activeTextClass('account')}`}
                        style={{
                          color: currentRoute?.includes('account')
                            ? colorTheme.label_selected_text_color
                            : colorTheme.label_text_color,
                        }}
                      >
                        {lastItemData.label[language]}
                      </span>
                    </button>
                  </li>
                )}

                {!miniProfile && (
                  <li onClick={() => setAccountMenuOpen(false)}>
                    <Link
                      href={lastItemData.link || ''}
                      className={`flex flex-col items-center space-y-1 p-2 ${activeLinkClass(
                        'account'
                      )}`}
                      style={{
                        backgroundImage: currentRoute?.includes('account')
                          ? `linear-gradient(to bottom, ${colorTheme.selected_icon_background_gradient[0]}, ${colorTheme.selected_icon_background_gradient[1]})`
                          : '',
                      }}
                    >
                      <UserIcon
                        className={`has-color fill-current ${activeIconClass(
                          'account'
                        )}`}
                      />
                      <span
                        className={`text-center text-custom-xs ${activeTextClass(
                          'account'
                        )}`}
                        style={{
                          color: currentRoute?.includes('account')
                            ? colorTheme.label_selected_text_color
                            : colorTheme.label_text_color,
                        }}
                      >
                        {lastItemData.label[language]}
                      </span>
                    </Link>
                  </li>
                )}
              </>
            )}
          </ul>
        </HydrationWrapper>
      </div>

      <div
        onClick={() => setAccountMenuOpen(false)}
        className={`fixed bottom-[69px] z-[56] max-h-[calc(100dvh-220px)] w-full overflow-auto bg-light-gray-200 p-4 transition-all duration-300 sm:w-[288px] sm:p-0 ${
          accountMenuOpen
            ? 'ltr:right-0 rtl:left-0'
            : 'ltr:-right-[4800px] rtl:-left-[4800px]'
        }`}
      >
        <AccountMobileMenu
          children={null}
          language={language}
          region={region}
        />
      </div>
      <div
        onClick={() => setAccountMenuOpen(false)}
        className={`fixed left-0 right-0 top-0 z-[49] h-screen w-full bg-black/75 backdrop-blur-sm ${
          accountMenuOpen ? 'block' : 'hidden'
        }`}
      ></div>
    </>
  );
}
