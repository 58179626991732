'use client';

import Image from 'next/image';
import { Link } from 'components/link';
import { useTranslation } from 'app/i18n/client';
import { FooterType } from 'types/cms/footer';
import clsx from 'clsx';
import { convertToAbsoluteURL } from 'utils';
import { Fragment } from 'react';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

export default function FooterBottom(props: FooterType) {
  const { t } = useTranslation(
    getLocalePathFromLanguageRegion(props.language, props.region),
    'footer'
  );

  return (
    <>
      <div
        className='w-full'
        style={{ background: props.colorTheme.static_links_background_color }}
      >
        <div
          className={clsx(
            'container-base block flex-col items-center justify-between gap-2 space-y-4 lg:flex',
            'py-4 md:gap-0 lg:flex-row lg:items-start lg:space-y-0 lg:px-0 lg:py-6'
          )}
        >
          <div className='block flex-col items-center justify-center gap-4 md:flex-row md:items-center lg:flex'>
            <div className='mb-4 flex items-center justify-between lg:mb-0'>
              <span className='text-custom-sm lg:hidden'>
                {props.copyrightLabel}
              </span>
              <div className='relative h-[64px] w-[130px]'>
                <Link href='/'>
                  <Image
                    src={props.logo || ''}
                    alt={t('nahdi_global') as string}
                    fill
                    sizes='130px'
                  />
                </Link>
              </div>
            </div>

            <div className='block flex-col items-center justify-center gap-2 text-[11px] text-black max-[300px]:text-[8px] sm:flex md:items-start md:text-custom-sm'>
              <span className='hidden lg:block'>{props.copyrightLabel}</span>
              <ul className='flex items-center justify-between gap-3 font-semibold sm:gap-4 lg:justify-start'>
                {props.staticLinks?.map((link, index) => (
                  <Fragment key={'static-links' + index}>
                    <Link
                      href={link.link || ''}
                      className={clsx(
                        'text-blue lg:text-black',
                        'font-semibold first:ltr:pl-0 first:rtl:pr-0',
                        'w-fit text-center first:text-start last:text-end sm:w-auto sm:text-left'
                      )}
                    >
                      {link.heading}
                    </Link>
                    <span className='block h-[11px] w-[1px] bg-light-gray last:hidden sm:bg-black'></span>
                  </Fragment>
                ))}
              </ul>
            </div>
          </div>
          <div
            className={clsx(
              'mt-3 border-t border-light-gray-300 pb-4 pt-3',
              'lg:mt-0 lg:border-none lg:p-0'
            )}
          >
            <span className='mb-3 block text-custom-base font-semibold text-black lg:hidden'>
              {t('payment_methods')}
            </span>
            <div
              className={clsx(
                'flex flex-row-reverse items-center max-[380px]:flex-col max-[380px]:gap-2 lg:flex-col lg:items-start lg:space-x-0',
                'xl:flex-row xl:items-center xl:space-x-8 rtl:space-x-reverse',
                'justify-start sm:justify-end'
              )}
            >
              {props.taxIcons && (
                <div className='flex items-center space-x-2 lg:!mx-0 lg:mt-4 ltr:ml-3 rtl:mr-3 rtl:space-x-reverse'>
                  {props.taxIcons.map((tax, index) => (
                    <div key={'tax-' + index}>
                      {tax.image && (
                        <Link
                          href={
                            tax.link
                              ? tax.linkType === 'EXTERNAL'
                                ? convertToAbsoluteURL(tax.link)
                                : tax.link
                              : '#'
                          }
                          target={
                            tax.linkType === 'EXTERNAL' ? '_blank' : '_self'
                          }
                        >
                          <Image
                            src={tax.image}
                            alt={t('tax_icon') as string}
                            width={0}
                            height={0}
                            sizes='100vw'
                            className='h-[35px] w-auto'
                          />
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
              )}
              <div className='flex flex-col items-start'>
                <span className='mb-3 hidden text-custom-base font-semibold text-black lg:block'>
                  {t('payment_methods')}
                </span>
                <div className='flex gap-2 lg:gap-3'>
                  {props.footerPaymentMethods?.map((paymentMethod, index) => (
                    <div
                      key={'payment-method-' + index}
                      className='relative h-[27px] w-10 max-[300px]:w-6'
                    >
                      <Image
                        src={paymentMethod.image}
                        alt={t('payment_method') as string}
                        fill
                        sizes='(max-width: 300px) 24px, 40px'
                        className='object-scale-down'
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className='w-full px-4 py-4 text-[10px] md:text-custom-sm lg:px-0 lg:text-center'
        style={{
          background: props.colorTheme.vat_registry_background_color,
          color: props.colorTheme.vat_registry_label_color,
        }}
      >
        {props.vatRegistryLabel}
      </div>
    </>
  );
}
