'use client';

import React from 'react';
import Image from 'next/image';
import { Link } from 'components/link';
import { FooterSocialItemType } from 'types/cms/footer';
import { gtmSetButtonClick } from 'lib/gtm';
import ContentfulLink from 'components/contentful-link';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function FooterSocialLinks({
  footerSocialItems,
  appstoreBlock,
  language,
  region,
}: {
  footerSocialItems: FooterSocialItemType[];
  appstoreBlock: FooterSocialItemType[];
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  return (
    <>
      <div className='flex flex-wrap justify-between gap-[18px] lg:flex-nowrap lg:justify-start lg:gap-4'>
        {footerSocialItems?.map((footerSocialLinkItem, index) => (
          <div
            key={'footer-single-social-item-' + index}
            onClick={() =>
              gtmSetButtonClick(
                footerSocialLinkItem.icon,
                footerSocialLinkItem.link
              )
            }
          >
            <ContentfulLink
              item={{
                link: footerSocialLinkItem.link,
                link_type: footerSocialLinkItem.linkType,
              }}
              className='block size-[45px] lg:size-[40px]'
              language={language}
              region={region}
            >
              <Image
                src={footerSocialLinkItem.icon}
                width={0}
                height={0}
                sizes='100vw'
                alt={footerSocialLinkItem.link}
                className='h-full w-full'
              />
            </ContentfulLink>
          </div>
        ))}
      </div>

      <div className='mt-4 flex items-center justify-between gap-[13px] lg:mt-6 lg:justify-start lg:gap-[20]'>
        {/* it can be download links via live site */}
        {appstoreBlock?.map((item, index: number) => (
          <Link
            key={'store-block' + index}
            href={item.link || ''}
            target='_blank'
            className='flex h-[50px] w-[174px] lg:w-[167px]'
          >
            <Image
              src={item.icon}
              alt={item.link}
              width={0}
              height={0}
              sizes='100vw'
              className='block h-full w-full'
              onClick={() => gtmSetButtonClick(item.icon, item.link)}
            />
          </Link>
        ))}
      </div>
    </>
  );
}
