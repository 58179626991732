export default function linkHandler(link: string) {
  switch (link) {
    case 'LOAD_MY_ACCOUNT_SCREEN':
      return '/account';
    case 'LOAD_MY_ACCOUNT_ADDRESS_BOOK_SCREEN':
      return '/account/address-book';
    case 'LOAD_MY_ACCOUNT_PAYMENT_CARDS_SCREEN':
      return '/account/payment-cards';
    case 'LOAD_MY_ACCOUNT_CUSTOMER_ORDERS_SCREEN':
      return '/account/my-orders';
    case 'LOAD_MY_ACCOUNT_CUSTOMER_REORDER_SCREEN':
      return '/account/re-order';
    case 'LOAD_MY_ACCOUNT_CUSTOMER_WISHLIST_SCREEN':
      return '/account/my-wishlist';
    case 'LOAD_MY_ACCOUNT_CUSTOMER_REFER_EARN_SCREEN':
      return '/account/refer-earn';
    case 'LOAD_MY_ACCOUNT_CONSENT_MANAGEMENT_SCREEN':
      return '/account/consent-management';
    default:
      return '/account';
  }
}
